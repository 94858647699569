import {
  DashboardOutlined,
  UserOutlined,
  ToolOutlined,
  SafetyCertificateOutlined,
  BellOutlined,
  KeyOutlined,
  SecurityScanOutlined,
  AuditOutlined,
  SafetyOutlined,
} from '@ant-design/icons';

export const menuItems = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    icon: <DashboardOutlined />,
    path: '/account/dashboard',
  },
  {
    key: 'profile',
    label: 'Profile Settings',
    icon: <UserOutlined />,
    path: '/account/profile',
  },
  {
    key: 'tools',
    label: 'Email Tools',
    icon: <ToolOutlined />,
    children: [
      {
        key: 'dmarc-inspector',
        label: 'DMARC Inspector',
        icon: <AuditOutlined />,
        path: '/account/dmarc-inspector',
      },
      {
        key: 'bimi-checker',
        label: 'BIMI Checker',
        icon: <SafetyOutlined />,
        path: '/account/bimi-checker',
      },
      {
        key: 'vmc-validator',
        label: 'VMC Validator',
        icon: <SafetyCertificateOutlined />,
        path: '/account/vmc-validator',
      },
      {
        key: 'spf-generator',
        label: 'SPF Generator',
        icon: <SecurityScanOutlined />,
        path: '/account/spf-generator',
      },
      {
        key: 'dkim-generator',
        label: 'DKIM Generator',
        icon: <KeyOutlined />,
        path: '/account/dkim-generator',
      },
      {
        key: 'dmarc-generator',
        label: 'DMARC Generator',
        icon: <BellOutlined />,
        path: '/account/dmarc-generator',
      },
      {
        key: 'bimi-generator',
        label: 'BIMI Generator',
        icon: <SafetyCertificateOutlined />,
        path: '/account/bimi-generator',
      },
    ],
  },
];

export default menuItems; 