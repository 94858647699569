'use client';
import { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';
import Header from './Header';

const HeaderWrapper = () => {
  const pathname = usePathname();
  const { data: session } = useSession();
  const [initialIsWhiteBackground, setInitialIsWhiteBackground] = useState(false);

  useEffect(() => {
    const whiteBackgroundPaths = [
      '/about', 
      '/bimi-checker', 
      '/dmarc-inspector', 
      '/vmc-validator',
    ];
    setInitialIsWhiteBackground(whiteBackgroundPaths.includes(pathname));
  }, [pathname]);

  return <Header session={session} initialIsWhiteBackground={initialIsWhiteBackground} />;
};

export default HeaderWrapper;