'use client';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { usePathname } from 'next/navigation';
import Image from 'next/image';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import {
  LoginOutlined,
  LogoutOutlined,
  ShoppingOutlined,
  UserOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import { useStateContext } from '@/context/StateContext';
import { signIn, signOut } from 'next-auth/react';
import { Tooltip, Button, Avatar, Dropdown } from 'antd';
import { menuItems } from '@/components/layout/menuConfig'; // Import account menu items

const NavBar = dynamic(() => import('./NavBar'), { ssr: false });
const DynamicCart = dynamic(() => import('@/components/Cart'), { ssr: false });

const Header = ({ session, initialIsWhiteBackground }) => {
  const pathname = usePathname();
  const [isMounted, setIsMounted] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const { showCart, setShowCart, totalQuantities } = useStateContext();

  useEffect(() => {
    setIsMounted(true);
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
      if (window.innerWidth >= 992) {
        setIsMobileNavOpen(false);
      }
    };
    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const linkClass = initialIsWhiteBackground
    ? 'header-nav-link on-white w-nav-link'
    : 'header-nav-link white w-nav-link';

  const navClass = initialIsWhiteBackground
    ? 'position-absolute full header-bg'
    : 'position-absolute full header-bg dark';

  const logoClass = initialIsWhiteBackground ? '-light' : '';

  const loginClass = initialIsWhiteBackground
    ? 'header-nav-link login hidden-on-tablet w-inline-block'
    : 'header-nav-link login hidden-on-tablet white w-inline-block';

  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const newOpacity = Math.min(window.scrollY / 20, 1);
      setOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState({
    Products: false,
    Resources: false,
  });

  const handleMouseEnter = useCallback((name) => {
    setIsDropdownOpen((prev) => ({ ...prev, [name]: true }));
  }, []);

  const handleMouseLeave = useCallback((name) => {
    setIsDropdownOpen((prev) => ({ ...prev, [name]: false }));
  }, []);

  const handleMobileNavToggle = useCallback(() => {
    setIsMobileNavOpen((prev) => !prev);
  }, []);

  // Define base navItems
  const navItems = [
    {
      name: 'Products',
      isDropdown: true,
      dropdownContent: [
        {
          title: 'Products',
          links: [
            {
              name: 'Mark Certificates',
              path: '/products/digicert-mark-certificates',
            },
            {
              name: 'Verified Mark Certificate',
              path: '/products/digicert-verified-mark-certificate',
            },
            {
              name: 'Common Mark Certificate',
              path: '/products/digicert-common-mark-certificate',
            },
            {
              name: 'DMARC Enforcement',
              path: '/products/easydmarc-enforcement',
            },
          ],
        },
      ],
    },
    { name: 'How It Works', path: '/how-it-works' },
    { name: 'About', path: '/about' },
    {
      name: 'Resources',
      isDropdown: true,
      dropdownContent: [
        {
          title: 'Support',
          links: [
            { name: 'Knowledgebase', path: '/knowledgebase' },
            { name: 'Articles', path: '/articles' },
            { name: 'FAQs', path: '/faqs' },
            { name: 'Glossary', path: '/glossary' },
          ],
        },
        {
          title: 'Tools',
          links: [
            { name: 'VMC Record Checker', path: '/vmc-validator' },
            { name: 'BIMI Record Checker', path: '/bimi-checker' },
            { name: 'DMARC Record Checker', path: '/dmarc-inspector' },
          ],
        },
      ],
    },
  ];

  // Organize menu items for logged-in users
  const getNavItems = useMemo(() => {
    if (!session) return navItems;

    // Add account menu items to Resources dropdown
    return navItems.map((item) => {
      if (item.name === 'Resources') {
        return {
          ...item,
          dropdownContent: [
            ...item.dropdownContent,
            {
              title: 'Account Tools',
              links: menuItems
                .filter((menuItem) => !menuItem.children)
                .map((menuItem) => ({
                  name: menuItem.label,
                  path: `/account/${menuItem.key}`,
                })),
            },
            {
              title: 'Email Tools',
              links:
                menuItems
                  .find((item) => item.key === 'tools')
                  ?.children.map((tool) => ({
                    name: tool.label,
                    path: `/account/${tool.key}`,
                  })) || [],
            },
          ],
        };
      }
      return item;
    });
  }, [session]);

  // Update memoizedNavBar to use getNavItems
  const memoizedNavBar = useMemo(
    () => (
      <NavBar
        linkClass={linkClass}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        isMobileNavOpen={isMobileNavOpen}
        handleMobileNavToggle={handleMobileNavToggle}
        isDesktop={isDesktop}
        navItems={getNavItems}
        session={session}
      />
    ),
    [
      linkClass,
      isDropdownOpen,
      isMobileNavOpen,
      isDesktop,
      getNavItems,
      session,
      handleMouseEnter,
      handleMouseLeave,
      handleMobileNavToggle,
    ]
  );

  const handleCartClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowCart((prev) => !prev); // Toggle cart visibility
  };

  // Helper function to get user initials
  const getUserInitials = (name) => {
    if (!name) return '?';
    return name
      .split(' ')
      .map((part) => part[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  // User menu items
  const userMenuItems = {
    items: [
      {
        key: 'dashboard',
        label: <Link href="/account/dashboard">Dashboard</Link>,
        icon: <DashboardOutlined />,
      },
      {
        key: 'profile',
        label: <Link href="/account/profile">Profile</Link>,
        icon: <UserOutlined />,
      },
      {
        type: 'divider',
      },
      {
        key: 'logout',
        label: 'Sign Out',
        icon: <LogoutOutlined />,
        onClick: () => signOut(),
      },
    ],
  };

  if (!isMounted) {
    return null; // Return null instead of HeaderSkeleton
  }

  return (
    <>
      {session && (
        <Link href="/account/dashboard">
          <Button
            type="primary"
            icon={<DashboardOutlined />}
            className="floating-dashboard-btn"
            size={!isDesktop ? 'middle' : 'large'}
          >
            {isDesktop ? 'Dashboard' : ''}
          </Button>
        </Link>
      )}
      <div role="banner" className="header-wrapper w-nav">
        <div className="position-relative z-index-1">
          <div className="container-wide w-container">
            <div className="header-content-wrapper">
              <Link href="/" className="header-logo-link w-nav-brand">
                <Image
                  src={`/images/logo${logoClass}.svg`}
                  alt="Brandymail.com logo"
                  className="header-logo"
                  width={150}
                  height={50}
                />
              </Link>
              <div className="header-middle">{memoizedNavBar}</div>
              <div className="header-right-side">
                <Tooltip placement="bottom" title="View your shopping cart">
                  <Link href="" className={loginClass} onClick={handleCartClick}>
                    <div style={{ position: 'relative' }}>
                      <ShoppingOutlined
                        className="color-accent-1"
                        style={{ fontSize: '24px', marginRight: '12px' }}
                      />
                      {totalQuantities > 0 && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '-4px',
                            right: '8px',
                            height: '8px',
                            width: '8px',
                            borderRadius: '50%',
                            backgroundColor: '#05c168',
                          }}
                        ></div>
                      )}
                    </div>
                  </Link>
                </Tooltip>
                {showCart && <DynamicCart />}
                {!session && (
                  <div>
                    <Tooltip placement="bottom" title="Sign in to your account">
                      <Link href="#" className={loginClass} onClick={() => signIn()}>
                        <LoginOutlined className="color-accent-1" style={{ fontSize: '24px' }} />
                      </Link>
                    </Tooltip>
                  </div>
                )}
                {session && (
                  <div>
                    <Dropdown menu={userMenuItems} placement="bottomRight" trigger={['click']}>
                      <div
                        className="user-menu-trigger"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          padding: '4px 8px',
                          borderRadius: '4px',
                          transition: 'background-color 0.3s',
                        }}
                      >
                        <Avatar
                          style={{
                            backgroundColor: '#05c168',
                            marginRight: '8px',
                          }}
                        >
                          {getUserInitials(session.user.name)}
                        </Avatar>
                        <span
                          className="user-name"
                          style={{
                            marginRight: '8px',
                            display: isDesktop ? 'block' : 'none',
                          }}
                        >
                          {session.user.name}
                        </span>
                      </div>
                    </Dropdown>
                  </div>
                )}
                <div
                  className={`hamburger-menu-wrapper w-nav-button ${
                    isMobileNavOpen ? 'w--open' : ''
                  }`}
                  onClick={handleMobileNavToggle}
                >
                  <div className="hamburger-menu-icon-wrapper">
                    <div
                      className="hamburger-menu-bar top"
                      style={
                        isMobileNavOpen
                          ? {
                              transform:
                                'translate3d(0px, 7px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(135deg) skew(0deg, 0deg)',
                              transformStyle: 'preserve-3d',
                            }
                          : {}
                      }
                    ></div>
                    <div
                      className="hamburger-menu-bar bottom"
                      style={
                        isMobileNavOpen
                          ? {
                              transform:
                                'translate3d(0px, -6px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg)',
                              transformStyle: 'preserve-3d',
                            }
                          : {}
                      }
                    ></div>
                  </div>
                </div>
                <a
                  href="/products/digicert-verified-mark-certificate"
                  className="btn-primary small header-btn-hidde-on-tablet w-button"
                  rel="noreferrer"
                >
                  Buy Now
                </a>
              </div>
            </div>
          </div>
          <div
            className={navClass}
            style={{
              opacity: initialIsWhiteBackground ? 1 : opacity,
            }}
          ></div>
        </div>
        {/* Mobile Nav Overlay */}
        {isMounted && (
          <div
            className={`w-nav-overlay ${isMobileNavOpen ? 'w--nav-overlay-open' : ''}`}
            style={{
              display: isMobileNavOpen ? 'block' : 'none',
              height: isMobileNavOpen ? '100vh' : '0',
            }}
          >
            {memoizedNavBar}
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
