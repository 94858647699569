import { useSession, signOut } from 'next-auth/react';
import { useEffect, useCallback, useRef } from 'react';
import { toast } from 'react-hot-toast';

export function useSessionTimeout() {
  const { data: session } = useSession();
  const lastActivityRef = useRef(Date.now());

  const handleActivity = useCallback(() => {
    lastActivityRef.current = Date.now();
  }, []);

  const handleTimeout = useCallback(() => {
    toast.custom(
      (t) => (
        <div className="custom-toast">
          <p>Your session has expired for security reasons. Your cart items are safely saved!</p>
          <button onClick={() => toast.dismiss(t.id)}>Dismiss</button>
        </div>
      ),
      {
        duration: Infinity,
      }
    );
    signOut({ callbackUrl: '/auth/signin?message=session_expired' });
  }, []);

  useEffect(() => {
    if (!session) return;

    const events = ['mousedown', 'keydown'];
    events.forEach((event) => window.addEventListener(event, handleActivity));

    const interval = setInterval(() => {
      const MAX_INACTIVITY = 2 * 60 * 60 * 1000;

      if (Date.now() - lastActivityRef.current > MAX_INACTIVITY) {
        handleTimeout();
      }
    }, 60000);

    handleActivity();

    return () => {
      events.forEach((event) => window.removeEventListener(event, handleActivity));
      clearInterval(interval);
    };
  }, [session, handleActivity, handleTimeout]);
}
