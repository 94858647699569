import Head from 'next/head';
import { SessionProvider } from 'next-auth/react';
import { StateContext } from '@/context/StateContext';
import { Toaster } from 'react-hot-toast';
import '../styles/normalize.css';
import '../styles/main.scss';
import RootLayout from '../src/app/layout';
import { useSessionTimeout } from '@/hooks/useSessionTimeout';

// Create a new component to handle session timeout
function SessionTimeoutWrapper({ children }) {
  useSessionTimeout();
  return children;
}

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="icon"
          href="/images/favicon.ico"
          sizes="48x48 96x96 144x144 256x256"
          type="image/x-icon"
        />
        <link rel="icon" href="/images/favicon-192.png" sizes="192x192" type="image/png" />
        <link rel="apple-touch-icon" href="/images/apple-touch-192.png" />
        <link rel="icon" href="/images/favicon-512.png" sizes="512x512" type="image/png" />
      </Head>
      <SessionProvider session={session}>
        <StateContext>
          <SessionTimeoutWrapper>
            <div className="toast-container">
              <Toaster
                toastOptions={{
                  className: '',
                  duration: 5000,
                  style: {
                    background: '#363636',
                    color: '#fff',
                  },
                }}
              />
            </div>
            <RootLayout>
              <Component {...pageProps} />
            </RootLayout>
          </SessionTimeoutWrapper>
        </StateContext>
      </SessionProvider>
    </>
  );
}

export default MyApp;
